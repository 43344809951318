@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&family=Roboto:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Round|Material+Icons+Outlined);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-icons-round.md-14 { font-size: 14px; }
.material-icons-round.md-16 { font-size: 16px; }
.material-icons-round.md-18 { font-size: 18px; }
.material-icons-round.md-24 { font-size: 24px; vertical-align: middle;}
.material-icons-round.md-28 { font-size: 28px; }
.material-icons-round.md-32 { font-size: 32px; }
.material-icons-round.md-36 { font-size: 36px; }
.material-icons-round.md-48 { font-size: 48px; }
.material-icons-outlined.md-14 { font-size: 14px; }
.material-icons-outlined.md-16 { font-size: 16px; }
.material-icons-outlined.md-18 { font-size: 18px; }
.material-icons-outlined.md-20 { font-size: 20px; }
.material-icons-outlined.md-22 { font-size: 22px; }
.material-icons-outlined.md-24 { font-size: 24px; }
.material-icons-outlined.md-28 { font-size: 28px; }
.material-icons-outlined.md-32 { font-size: 32px; }
.material-icons-outlined.md-36 { font-size: 36px; }
.material-icons-outlined.md-48 { font-size: 48px; }


html, body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-family: Comfortaa, Helvetica Neue, sans-serif;
  min-width: 100vw;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.App {
  text-align: center;
}
.center {
  text-align: center;
}

.flexy{
  justify-content: center;
  align-items: center;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

a {
  color: black;
  text-decoration: underline;
}

h2 {
  font-family: Roboto, Helvetica Neue, sans-serif;
}



* {
  box-sizing: border-box;
}

.App-logo {
  margin: 50px;
  height: 200px;
}

.bannerImage {
  max-width: 700px;
}

.banner{
  width: 100%;
  padding: 35px;
  text-align: left;
  overflow: hidden;
}

.hero {
  width: 100%;
  height: 80vh;
  margin: 0px;
  vertical-align: middle;
  border:0;
  overflow: hidden;
}
@media screen and (max-width: 450px) {
  .hero {
    width: 100%;
    height: 55vh;
    margin: 0px;
    vertical-align: middle;
    border:0;
    overflow: hidden;
  }
}

.heroImg{
  width: 100%;
  height: 100%;
  padding: 0px;
  object-fit: cover;
  overflow: hidden;
  position: center center;
  vertical-align: middle;
  border:0;
  margin: 0px;
  -webkit-clip-path: polygon(0 10vw, 100% 0, 100% calc(100% - 10vw), 0% 100%);
  clip-path: polygon(30% 100%, 100% 80%, 100% 10%, 70% 0, 0 10%, 0 80%);
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.backImgContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-color: rgba(47, 54, 64,0.7);
}

.backImgContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -2;
}


.backImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  position: center center;
  vertical-align: middle;
}


.ContactCard{
  text-align: center;
  padding: 0px;
  margin: 0px;
  width: 100%;
  padding-top: 10px;
}

.ContactCard h2{
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 28px;
  color:black;
  font-size: 18px;
}

.StripeElement {
  box-sizing: border-box;
  max-width: 450px;
  height: 40px;
  margin : 0 auto;
  margin-bottom: 20px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #b3b6b9;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #989a9c;
}

.StripeElement--invalid {
  border-color: #B5251C;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripeCustField {
  width: 100%;
  max-width: 450px;
  font-size: 18px;
  box-shadow: 0 1px 3px 0 #b3b6b9;
  -webkit-appearance: none;
}
.label {
  display: block;
  margin: auto;
  text-align: left;
  width: 100%;
  max-width: 450px;
}
.labelRed {
  display: block;
  margin: auto;
  text-align: left;
  width: 100%;
  max-width: 450px;
  color: #B5251C;
}

.redtext{
  color: #B5251C;
}

.boldish{
  font-weight: 600;
}



.formContainer{
  margin: 0px;
  padding: 10px;
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  background-color:white;
  width: 100%;
}

.formContainer textarea{
  height: 150px;
  font-family: Comfortaa, Helvetica Neue, sans-serif;
}

.mainButton {
  text-transform: uppercase;
  background-color: black;
  border: 3px solid transparent;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Comfortaa, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
}

.mainButton:disabled {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}
.mainButton:disabled:hover {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}

.mainButton:hover{
  background-color:transparent;
  border: 3px solid white;
  color:white;
  transition: all 0.3s ease;
}

.mainButtonContrast {
  text-transform: uppercase;
  background-color: black;
  border: 3px solid transparent;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  font-family: Comfortaa, Helvetica Neue, sans-serif;
  font-weight: bolder;
  transition: all 0.3s ease;
  position: relative;
  z-index: 10;
}

.mainButtonContrast:hover{
  background-color:transparent;
  border: 3px solid black;
  color:black;
  transition: all 0.3s ease;
}

.mainButtonContrast:disabled {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}
.mainButtonContrast:disabled:hover {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}


.secondaryButton {
  text-transform: uppercase;
  background-color: rgba(0, 0, 0,0.4);
  border: 3px solid white;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Comfortaa, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
}

.secondaryButton:disabled {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}
.secondaryButton:disabled:hover {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}

.secondaryButton:hover{
  background-color:black;
  border: 3px solid black;
  color: white;
  transition: all 0.3s ease;
}

footer{
  text-align: left;
  background-color: white;
  padding: 15px;
  border-top:3px solid black;
  line-height: 1.5em;
  padding-left: 10vw;
  padding-right: 10vw;
}
.powered {
  color: grey;
  font-size: 13px;
}

.lightLine{
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: gray;
  padding: 0px;
  margin: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.textCenter{
  text-align: center;
}

p.thin{
  width: 100%;
  text-align: center;
  background-color: transparent;
  line-height: 1.5em;
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.footerBrand {
  width:130px;
  height: 130px;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

p.footer {
  color: black;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
}
.footerInfo {
  display: inline-block;
  position: relative;
  margin-left: 25px;
  vertical-align: middle;
}

@media screen and (max-width: 450px) {
  footer{
    width: 100%;
    text-align: center;
    background-color: white;
    padding: 15px;
    border-top:3px solid black;
    line-height: 1.5em;
    padding-left: 10vw;
    padding-right: 10vw;
  }
}

.spacer{
  width: 100%;
  height: 25px;
  margin: 0;
  padding: 0;
}

.textContainer {
  margin: 25px;
  margin-bottom: 25px;
  max-width: 700px;
  text-align: justify;
}

h2.textContainer {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 28px;
  vertical-align: middle;
  margin-bottom: 0px;
  text-align: left;
}
p.textContainer{
  font-weight: 400;
  line-height: 1.4em;
  font-size: 20px;
  margin-bottom: 0px;
}

.inlineGlyph{
  height: 35px;
  width: 35px;
  vertical-align: middle;
}

.inlineGlyphSmall{
  height: 22px;
  width: 22px;
  vertical-align: middle;
  opacity: 1.0;
}

.backGlyph{
  width: 50%;
  position: absolute;
  right: -35%;
  top: 15%;
}

.productImage {
  width: 100%;
  max-height: 600px;
  /* height: 600px; */
  object-fit: cover;
}

.textContainerBox{
  border-radius: 20px;
  overflow: hidden;
  /* border: 1px solid transparent; */
  box-shadow: 0 1px 10px 4px #b3b6b9;
}

.buyButtonDiv{
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.fadeInOut{
  -webkit-animation: fadein 2.0s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadein 2.0s;
}
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}
@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

